import React from 'react';
import device from '../images/device.png';
import water from '../images/water.png';
import electric from '../images/electric.png';
import oxygen from '../images/oxygen.png';
import h2o2 from '../images/h2o2.png';
import soda from '../images/caustic-soda.png';
import Marker from './marker';
import './container.less';
import { motion } from 'framer-motion';

function Container() {
  return (
    <motion.svg
      viewBox='0 0 800 450'
      whileInView={{ scale: 1.05 }}
      transition={{ duration: 3 }}
    >
      <image width={800} height={450} href={device} />
      <line x1={125} y1={87} x2={125} y2={169} strokeWidth={1} stroke="#ed7d31" />
      <rect x={8} y={46} width={170} height={82} rx={6} fill="#fff" filter="drop-shadow(rgb(0 0 0 / 30%) 2 2 3)"/>
      <text className='device-tip-title' x={24} y={70} fill="#ed7d31">Input</text>
      <text className='device-tip-sub-title' x={18} y={112} fill="#ed7d31">Water</text>
      <image href={water} height={16} x={24} y={82} />
      <text className='device-tip-sub-title' x={50} y={112} fill="#ed7d31">Electricity</text>
      <image href={electric} height={16} x={65} y={82} />
      <text className='device-tip-sub-title' x={100} y={112} fill="#ed7d31">Oxygen</text>
      <image href={soda} height={16} x={150} y={82} />
      <text className='device-tip-sub-title' x={140} y={108} fill="#ed7d31">Caustic</text>
      <text className='device-tip-sub-title' x={146} y={118} fill="#ed7d31">Soda</text>
      <image href={oxygen} height={16} x={104} y={82} />
      {/* input */}
      <Marker color='#ed7d31' x={115} y={158} width={20} height={20} />
      {/* core */}
      <line x1={367} y1={242} x2={367} y2={370} strokeWidth={1} stroke="#37acbf" />
      <rect x={271} y={350} width={220} height={44} rx={6} fill="#37acbf" filter="drop-shadow(rgb(0 0 0 / 50%) 2 2 3)"/>
      <text className='device-tip-title' x={288} y={376} fill="#fff">Phase Two Chemicals stack</text>
      <Marker color='#37acbf' x={357} y={227} width={20} height={20} />
      {/* output */}
      <line x1={708} y1={281} x2={708} y2={360} strokeWidth={1} stroke="#37acbf" />
      <rect x={630} y={328} width={158} height={79} rx={6} fill="#37acbf" filter="drop-shadow(rgb(0 0 0 / 50%) 2 2 3)"/>
      <text className='device-tip-title' x={646} y={354} fill="#fff">Output</text>
      <text className='device-tip-sub-title' x={676} y={376} fill="#fff">Hydrogen Peroxide in</text>
      <text className='device-tip-sub-title' x={676} y={391} fill="#fff">caustic soda solution</text>
      <image href={h2o2} height={24} x={644} y={367} />
      <Marker color='#37acbf' x={698} y={264} width={20} height={20} />
    </motion.svg>
  );
}

export default Container;
