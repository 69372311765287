import { Center, Image, Title } from "@mantine/core";
import React from "react";
import BatteryAnimation from "../components/batteryAnimation";
import powerGrid from "../videos/power-grid.mp4";
import p2x from '../images/p2x.jpg';
import lowerCost from '../images/lower-cost.svg';
import safe from '../images/safe.svg';
import efficient from '../images/efficient.svg';
import yingde from '../images/yingde.svg';
import yingdeInverse from '../images/yingde-inverse.svg';
import solvay from '../images/solvay.svg';
import solvayInverse from '../images/solvay-inverse.svg';
import shenergy from '../images/shenergy.svg';
import shenergyInverse from '../images/shenergy-inverse.svg';
import { Carousel } from "@mantine/carousel";
import './index.less';
import Container from "../components/container";
import { useMediaQuery } from '@mantine/hooks';
import SEO from "../components/seo";

const customers = [
  { normal: yingde, inverse: yingdeInverse, },
  { normal: solvay, inverse: solvayInverse, },
  { normal: shenergy, inverse: shenergyInverse, },
];

const IndexPage = () => {
  const hideP2xTitleBr = useMediaQuery('(max-width: 1439px)');
  const sliderSm = useMediaQuery('(max-width: 768px)');

  return <div className="home">
    <section className="bg">
      <video src={powerGrid} autoPlay loop muted></video>
      <div className="text-tag">We use advanced electrochemical technology to decarbonize traditional industrial processes !</div>
    </section>
    <section className="p2x">
      <Title className="title" order={1} id="p2x">
        We are dedicated to the research and development of Power-to-Chemicals{!hideP2xTitleBr && <br />}
      </Title>
      <Image src={p2x} fit="cover"/>
    </section>
    <section className="device">
      <div>
        <Title className="title" order={1}>Disruptive Innovation-Large Scale Electrochemical Equipment for the On-side Production of Hydrogen Peroxide</Title>
        <Title className="sub-title" order={2}>Green and environmentally friendly, only consumes water, electricity, and oxygen</Title>
      </div>
      <div className="container">
        <Container />
      </div>
    </section>
    <section className="customers">
      <Title className="title" order={1}>Our Partners</Title>
      <Title className="sub-title" order={2}>We promote cooperation for innovative, green solutions with our partners in the manufacturing and energy sectors.</Title>
      <Carousel
        slideSize="33.3333%"
        initialSlide={1}
        slidesToScroll={1}
        align="center"
        speed={8}
        withControls={!sliderSm}
        withIndicators
        loop
      >
        {customers.map(({normal, inverse}, index) => (<Carousel.Slide className="customer-logo" key={index}>
          <Center>
            <div className="image-container">
              <img className="inverse" src={inverse} />
              <img className="normal" src={normal} />
            </div>
          </Center>
        </Carousel.Slide>))}
      </Carousel>
    </section>
  </div>;
}

export default IndexPage

export const Head = () => (<SEO />)
